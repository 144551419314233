import * as Sentry from "@sentry/react";

/**
 * List of sentry tags that will be indexed in sentry search feature
 */
export enum SentryTags {
	Authentication = "Authentication",
	Chat = "Chat",
	ChatPresence = "ChatPresence",
	ClientManagement = "ClientManagement",
	ClientNetwork = "ClientNetwork",
	Contact = "Contact",
	Crash = "Crash", // Portal crashes
	CallWindowCrash = "CallWindowCrash", // Portal call window crashes
	CheckInFeeling = "CheckInFeeling",
	CustomTracker = "CustomTracker",
	DiaryEntry = "DiaryEntry",
	FeatureFlags = "FeatureFlags",
	Invitation = "Invitation",
	Intervention = "Intervention",
	IWC = "InterWindowCommunication",
	MessageInbox = "MessageInbox",
	MeetingNotes = "MeetingNotes",
	MoodChart = "MoodChart",
	NDD = "NeedDrivenDashboard",
	Organization = "Organization",
	Tracking = "Tracking",
	VideoCall = "VideoCall",
	EMDR = "EMDR",
	EmailVerification = "EmailVerification",
	InactiveClient = "InactiveClient",
	Freshdesk = "Freshdesk",
	UserProfile = "UserProfile",
	ServiceStatus = "ServiceStatus",
	UserSettings = "UserSettings",
	TreatmentStatus = "TreatmentStatus",
	ThoughtRecord = "ThoughtRecord",
	TherapistTimeTracking = "TherapistTimeTracking",
	VersionCheck = "VersionCheck",
	PrivateNotes = "PrivateNotes",
	PlanActivity = "PlanActivity",
	PsychoEducation = "PsychoEducation",
	FileSharing = "FileSharing",
	TwoFA = "TwoFA",
	Calendar = "Calendar",
	TherapySession = "TherapySession",
	BehaviorExperiment = "BehaviorExperiment",
	NDBlogSearch = "NDBlogSearch",
	Assessment = "Assessment",
	XAI = "XAI",
}

/**
 * Creates helpers for sentry reporting
 *
 * @param {SentryTags} tag could be a module name to be tagged in sentry so that we can easily search the logs
 */
export default function createSentryReport(domain?: SentryTags) {
	/**
	 * Set default context.
	 * If domain is passed, all call to helpers below will be tagged in sentry.
	 * There is also an option to set extra informations to sentry
	 *
	 * @see https://docs.sentry.io/platforms/javascript/#adding-context
	 * @see https://docs.sentry.io/enriching-error-data/context/?platform=browser#tagging-events
	 * @see https://docs.sentry.io/enriching-error-data/context/?platform=browser#extra-context
	 */
	const setDefaultContext = (scope: Sentry.Scope, extras?: Record<string, any>) => {
		if (domain) {
			scope.setTag("domain", domain);
		}
		if (extras) {
			scope.setExtras(extras);
		}
	};

	const captureException = (e: Error, extras?: Record<string, any>) => {
		let eventId: string;
		Sentry.withScope((scope) => {
			scope.setLevel(Sentry.Severity.Error);
			setDefaultContext(scope, extras);
			eventId = Sentry.captureException(e);
		});
		return eventId;
	};

	const captureMessage = (severity: Sentry.Severity, message: string, extras?: Record<string, any>) => {
		let eventId: string;
		Sentry.withScope((scope) => {
			setDefaultContext(scope, extras);
			eventId = Sentry.captureMessage(message, severity);
		});
		return eventId;
	};

	return {
		captureErrorMessage: (message: string, extras?: Record<string, any>) => {
			return captureMessage(Sentry.Severity.Error, message, extras);
		},
		captureWarningMessage: (message: string, extras?: Record<string, any>) => {
			return captureMessage(Sentry.Severity.Warning, message, extras);
		},
		captureException: (e: Error, extras?: Record<string, any>) => {
			return captureException(e, extras);
		},
		/**
		 * Manually add breadcrumb for extra information to the report
		 *
		 * https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=browser#recording-crumbs
		 */
		addBreadcrumb: (breadcrumb: Sentry.Breadcrumb) => {
			Sentry.addBreadcrumb(breadcrumb);
		},
	};
}
